.control {
    --background: var(--form_background, var(--color-white));
    --color: var(--form_color, var(--color-black));
    --focus-color: var(--form_focus-color, var(--color-black));
    --border-color: var(--form_border-color, var(--color-white-darken-5));
    --label-color: var(--form_label-color, var(--color-black));
    --selection-background: var(--form_selection-background, var(--border-color));
    --border-radius: var(--form_border-radius, var(--global-border-radius));
    width: 100%;
    margin: var(--gap) auto calc(3 * var(--gap));
    position: relative;
}

.container {
    width: 100%;
}

.input,
.label {
    padding: calc(0.75 * var(--gap)) var(--gap);
}

.input {
    width: 100%;
    overflow: hidden;
    background: hsl(var(--background));
    color: hsl(var(--color));
    caret-color: hsl(var(--border-color));
    border-radius: var(--border-radius);
    border: 1px solid hsl(var(--border-color));

    &:not(.disabled):focus {
        box-shadow: 0 0 0 3px hsla(var(--focus-color), 0.25);
    }

    &:focus,
    &[placeholder] {

        + .label {

            .labelContent {
                transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
            }
        }
    }

    &:focus {
        border-radius: var(--border-radius);
    }

    &::placeholder {
        color: hsla(var(--form_placeholder-color, var(--color-black)), 0.4);
        font-size: var(--font-size-00);
    }

    &::selection {
        background: hsla(var(--selection-background), 0.75);
        transition: background-color var(--transition-out) var(--transition-timing);
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.label {
    width: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    font-weight: 700;
    border: 1px solid transparent;
}

.labelContent {
    display: inline-flex;
    transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
    transform-origin: left center;
    transition-property: transform;
    transition-duration: var(--transition-in);
    transition-timing-function: var(--ease-out-back);
}

.required {
    position: absolute;
    top: calc(0.6 * var(--gap));
    left: calc(0.4 * var(--gap));
    z-index: var(--zindex-required);
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: var(--font-size-00);
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));

    &,
    .input:focus,
    .input:hover {
        --focus-color: var(--variant);
        --border-color: var(--variant);
        --label-color: var(--variant);
    }
}
